import React, { useContext } from 'react'
import { Link, Box } from 'theme-ui'
import { store } from '../../context/store'
import { FaMapMarkedAlt } from 'react-icons/fa'

export default function Logo(props) {
  const { globalState, dispatch } = useContext(store)

  const { name, street, city, state, zip } = globalState.businessData

  const googleString = `https://www.google.com/maps/dir/?api=1&destination=${name}+${street}+${city}+${state}+${zip}`

  return (
    <Link href={googleString} target="_blank" rel="noreferrer" {...props} sx={{ ...styles.container, ...props.sx }}>
      <FaMapMarkedAlt />
      <Box>
        {`${street}`}
        <br></br>
        {`${city}, ${state}, ${zip}`}
      </Box>
    </Link>
  )
}

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'inherit',
    fontSize: '0.65rem',
    svg: {
      width: '20px',
      height: '20px',
      marginRight: '2',
      color: 'primary'
    }
  }
}
