import React, { useState, useEffect, useCallback, useRef, useContext } from 'react'
import { Link } from 'gatsby'
import { Box, Image, Button, Link as ExternalLink } from 'theme-ui'
import { GiHamburgerMenu } from 'react-icons/gi'
import { disablePageScroll, enablePageScroll } from 'scroll-lock'
import { store } from '../../context/store'
import Fade from 'react-reveal/Fade'
import { globalHistory } from '@reach/router'
import Phone from '../UI/Phone'
import OrderOnline from '../UI/OrderOnline'
import BottomNav from './BottomNav'
import { AiOutlineClose } from 'react-icons/ai'
import ReservationsPopUp from '../UI/ReservationsPopUp'
import Logo from '../UI/Logo'
import DropdownNavItem from './dropdownComponents/DropdownNavItemV1'
import findPoweredImageURLById from '../../helpers/findPoweredImageURLById'

export default function Header(props) {
  const { globalState, dispatch } = useContext(store)

  const { businessData, configData, poweredImages, stories } = globalState

  const isMenuPage = props.location.pathname === '/menu/' || props.location.pathname === '/menu'

  const [navOpen, setNavOpen] = useState(false)
  const [scrolled, setScrolled] = useState(isMenuPage ? true : false)

  const navRef = useRef()
  navRef.current = scrolled

  const handleScroll = useCallback(() => {
    if (isMenuPage) {
      setScrolled(true)
    } else {
      const show = window.scrollY > 70
      if (navRef.current !== show) {
        setScrolled(show)
      }
    }
  }, [isMenuPage])

  const navMenuBg = findPoweredImageURLById('navigation-menu-bg', poweredImages)

  const navMenuBgStyle = {
    backgroundImage: `url(${navMenuBg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center'
  }

  // sets state to scrolled if the user is not at the top of the page
  useEffect(() => {
    document.addEventListener('scroll', handleScroll)

    if (isMenuPage) {
      setScrolled(true)
    }

    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll, isMenuPage])

  // disable scroll when nav open
  useEffect(() => {
    navOpen ? disablePageScroll() : enablePageScroll()
    return () => {}
  }, [navOpen])

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') setNavOpen(false)
    })
  }, [navOpen])

  const renderDesktopNavLinks = (link, navIndex, isNavBar) => {
    if (link?.dropdownLinks) {
      return (
        <DropdownNavItem
          key={`${link.name}${navIndex}`}
          navLinks={link}
          navIndex={`${isNavBar ? 'navBar' : 'navMenu'}${navIndex}`}
          isNavBar={isNavBar}
        />
      )
    } else {
      return (
        <Link sx={styles.navMenuItem} to={link.link}>
          {link.name}
        </Link>
      )
    }
  }

  const toggleOnlineOrderWidget = () => {
    setNavOpen(false)
    dispatch({
      type: 'TOGGLE_ONLINE_ORDERING_WIDGET'
    })
  }
  const toggleReservationsWidget = () => {
    setNavOpen(false)
    dispatch({
      type: 'TOGGLE_RESERVATIONS_WIDGET'
    })
  }

  const navOpenVariant = navOpen ? styles.navMenuOpen : {}
  const navBlockOpenVariant = navOpen ? styles.navBlockOpen : {}

  const containerScrolledVariant = scrolled || globalState.appState.reservationsPopUp ? styles.containerScrolled : {}
  const logoScrolledVariant = scrolled || globalState.appState.reservationsPopUp ? styles.logoContainerScrolled : {}

  return (
    <>
      <Box sx={{ ...styles.container, ...containerScrolledVariant }} as="header">
        <Box sx={styles.logoContainerOuter}>
          <Fade left>
            <Link to="/">
              <Box sx={{ ...styles.logoContainer, ...logoScrolledVariant }}>
                <Logo />
              </Box>
            </Link>
          </Fade>
        </Box>
        {/* <Phone sx={styles.phone} /> */}
        <Fade right>
          <Box sx={styles.hamburgerContainer} onClick={() => setNavOpen(!navOpen)}>
            <GiHamburgerMenu size={30} />
            {/* Menu */}
          </Box>
        </Fade>

        <Box sx={styles.desktopNav} className="desktopNav">
          <Fade bottom cascade>
            {props.navLinks.map((link, index) => renderDesktopNavLinks(link, index, true))}
          </Fade>
        </Box>
        <Box sx={styles.ctaButtons}>
          <Link sx={{ ...styles.onlineOrder, width: '100%', display: ['', 'none'] }} href={'menu'}>
            Menu
          </Link>
          <OrderOnline sx={styles.onlineOrder} />
          <ReservationsPopUp sx={styles.reservations} />
        </Box>
      </Box>

      <Box sx={{ ...styles.navMenu, ...navMenuBgStyle, ...navOpenVariant }}>
        <AiOutlineClose sx={styles.closeBtn} onClick={() => setNavOpen(false)} />
        <Box sx={styles.links}>
          <Link to="/">
            <Logo sx={styles.navMenuLogo} />
          </Link>
          {props.navLinks.map((link, index) => (
            <Fade top delay={index + 1 * 500}>
              {renderDesktopNavLinks(link, index, false)}
            </Fade>
          ))}

          {/* <Button sx={styles.cta} onClick={toggleReservationsWidget}>
            Reservations
          </Button> */}
        </Box>
      </Box>
      <Box sx={{ ...styles.navBlock, ...navBlockOpenVariant }} onClick={() => setNavOpen(false)}></Box>

      <BottomNav />
    </>
  )
}
const styles = {
  container: {
    display: 'flex',
    justifyContent: ['space-between', '', 'flex-start'],
    backgroundColor: 'transparent',
    alignItems: ['center', '', 'center'],
    position: 'fixed',
    width: '100%',
    top: '0rem',
    padding: ['0.5rem 1rem', '1rem', '', '0rem 1rem', '0rem 2rem 0rem 0rem'],
    zIndex: '5000',
    transition: 'all ease-in-out 0.5s',
    // borderBottom: 'solid 1px lightgrey',
    height: '80px',
    zIndex: '5000'
  },
  containerScrolled: {
    padding: ['0.5rem 1rem', '1rem', '', '0rem 1rem 0rem 0rem', '0rem 2rem 0rem 0rem'],
    backgroundColor: '#0c1b30cc',
    // borderBottom: 'solid 1px lightgrey',
    '.desktopNav': {
      // backgroundColor: '#202020d4',
    }
  },
  logoContainerOuter: {
    alignSelf: 'flex-start'
  },
  logoContainer: {
    maxWidth: ['150px', '250px'],
    marginRight: '1.5rem',
    paddingRight: '0.5rem',
    // borderRight: 'solid 1px lightgrey',
    transition: 'all ease-in-out 0.5s',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    img: {
      // filter: 'brightness(0)',
    }
  },
  logoContainerScrolled: {
    maxWidth: ['110px', '125px'],
    img: {
      // filter: 'brightness(0)',
    }
  },
  logo: {
    width: '100%'
  },
  hamburgerContainer: {
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '0.8rem',
    cursor: 'pointer',
    svg: {
      marginRight: '1',
      transition: 'all ease-in-out 0.5s',
      borderRadius: '100px',
      padding: '0.25rem',
      color: 'primary'
    },
    transition: 'all ease-in-out 0.5s',
    ':hover': {
      opacity: '0.7'
    }
  },
  desktopNav: {
    padding: ['', '', '0rem 1rem', '0rem 1rem', '0rem 2rem'],
    display: ['none', '', '', 'flex'],
    margin: ['0 0 0 auto'],
    height: '100%',
    a: {
      variant: 'buttons.textOnly',
      padding: '0.5rem',
      color: 'white',
      height: '100%',
      textAlign: 'center',
      fontSize: ['0.9rem', '', '', '', '1rem'],
      margin: '0rem 0rem',
      ':hover': {
        textDecoration: 'underline'
      }
    }
  },

  phone: {
    display: ['none', '', 'flex'],
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    svg: {
      color: 'primary',
      marginRight: '2',
      width: '1rem',
      height: '1rem'
    },
    fontWeight: 'bold',
    fontSize: '0.8rem',
    margin: '0rem 1rem 0rem 0.25rem',
    ':hover': {
      textDecoration: 'underline'
    }
  },
  ctaButtons: {
    display: 'flex',
    flexWrap: ['wrap', 'nowrap'],
    position: ['fixed', 'static'],
    left: '0rem',
    bottom: '42px',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0rem 0rem 0rem auto',
    width: ['100%', 'fit-content']
  },
  onlineOrder: {
    margin: ['0rem', '0rem 0rem 0rem 0.5rem'],
    fontSize: '0.7rem',
    borderRadius: ['0px', '', '0px'],
    fontWeight: 'bold',
    textTransform: 'uppercase',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    fontFamily: 'subheading',
    letterSpacing: '1px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    cursor: 'pointer',
    flexGrow: ['1', 'unset'],
    backgroundColor: ['primary', 'transparent'],
    border: ['solid 1px'],
    borderColor: ['white', 'primary'],
    padding: '1rem 2rem'
  },

  reservations: {
    margin: ['0rem', '0rem 0rem 0rem 0.5rem'],
    borderRadius: ['0px', '', '0px'],
    backgroundColor: ['primary', 'transparent'],
    display: 'flex',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    fontFamily: 'subheading',
    letterSpacing: '1px',
    flexGrow: ['1', 'unset'],
    cursor: 'pointer',
    fontSize: '0.7rem',
    border: ['solid 1px'],
    borderColor: ['white', 'primary'],
    padding: '1rem 2rem'
  },
  navMenu: {
    position: 'fixed',
    left: '-150vw',
    backgroundColor: 'white',
    height: '100vh',
    width: '100%',
    zIndex: '6000',
    transition: 'all ease-in-out 0.5s',
    padding: ['1rem 2rem', '1rem 3rem'],
    display: 'flex',
    justifyContent: ['flex-start', 'center'],
    alignItems: 'center',
    flexDirection: 'column'
  },
  navMenuOpen: {
    left: '0vw'
  },

  navMenuItem: {
    variant: 'customStyles.iconText',
    color: 'grey',
    fontSize: ['1.5rem', '', '2rem'],
    textAlign: 'center',
    width: '100%',
    justifyContent: 'center',
    cursor: 'pointer',
    margin: ['0.5rem 0rem', '0.75rem 0rem', '0rem 0rem'],
    // textShadow: '2px 2px 10px black',
    letterSpacing: '1px',
    svg: {
      color: 'primary',
      marginRight: '0.5rem'
    },
    ':hover': {
      color: 'primary'
    }
  },
  closeBtn: {
    position: 'absolute',
    right: '1rem',
    top: '1rem',
    width: '40px',
    height: '40px',
    padding: '0.5rem',
    backgroundColor: 'primary',
    color: 'white',
    borderRadius: '500px',
    opacity: '0.7'
  },
  navBlock: {
    position: 'fixed',
    left: '-100vw',
    backgroundColor: 'rgba(0,0,0,0.5)',
    backdropFilter: 'blur(2px)',
    height: '100vh',
    width: '100vw',
    zIndex: '5500',
    transition: 'all ease-in-out 0.5s'
  },
  navBlockOpen: { left: '0vw' },

  navMenuLogo: {
    maxWidth: ['150px', '200px'],
    marginBottom: ['1rem', '2rem', '2rem']
  },
  links: {
    maxHeight: '87.5vh',
    overflowY: 'scroll',
    width: '100%',
    '::-webkit-scrollbar': {
      display: 'none'
    },
    MsOverflowStyle: 'none' /* IE and Edge */,
    scrollbarWidth: 'none' /* Firefox */,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    margin: 'auto',
    a: {
      color: 'white',
      lineHeight: ['1.4', '1.5', '1.75'],
      ':hover': {
        color: 'lightgrey'
      }
    }
  },
  cta: {
    margin: '0.5rem 0rem 0.5rem',
    padding: '0.5rem 1rem',
    backgroundColor: 'primary',
    fontFamily: 'subheading',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderRadius: '0px',
    border: 'solid 1px',
    borderColor: 'primary',
    fontSize: ['1rem', '', '1.1rem']
  }
}
