import './src/gatsby-plugin-theme-ui/fonts.css'
import React from 'react'
import Layout from './src/components/Layout'
import { ContextProvider, store } from './src/context/store'

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return (
    <ContextProvider>
      <Layout {...props}>{element}</Layout>
      <form name="contact-form" data-netlify="true" netlify-honeypot="bot-field" hidden>
        <input type="text" name="name" />
        <input type="tel" name="phone" />
        <input type="email" name="email" />
        <textarea name="message"></textarea>
      </form>

      <form name="private-dining-form" data-netlify="true" netlify-honeypot="bot-field" hidden>
        <input type="text" name="firstname" />
        <input type="text" name="lastname" />
        <input type="tel" name="phone" />
        <input type="email" name="email" />
        <input name="typeofevent" type="text" />
        <input name="date" type="date" />
        <input name="flexibledate" type="checkbox" />
        <input name="eventtime" type="time" />
        <input name="numberguests" type="number" />
        <input name="companyname" type="text" />
        <textarea name="message"></textarea>
      </form>
    </ContextProvider>
  )
}
