import { graphql, useStaticQuery } from 'gatsby'

const useStories = () => {
  const data = useStaticQuery(graphql`
    query storiesQuery {
      allGeneral {
        edges {
          node {
            articleType
            author
            available
            body
            bodyHTML
            bodyPlainText
            createdAt
            ctaLink {
              title
              url
            }
            media {
              cloudinaryId
              description
              name
            }
            ctaLinks {
              title
              url
            }
            description
            flags
            id
            locationIndex
            name
            readingTime
            readingTimeText
            subtitle
            tags
            title
          }
        }
      }
    }
  `)

  const storiesList = data?.allGeneral.edges.map(({ node }) => node)

  const getStoryById = id => storiesList.filter(story => story.id === id)

  const getStoriesByTag = tag => storiesList.filter(story => story?.tags?.includes(tag))

  return {
    stories: storiesList,
    getStoryById,
    getStoriesByTag
  }
}

export default useStories
