/**
 *
 * A Selector that Decides TO Use Internal Routing of External Link
 *
 * @param {string} name - link label name
 * @param {string} link - The URL
 * @param {Component} icon - Takes an Icon Component or image component.
 *
 */

/** @jsxImportSource theme-ui */

import React from 'react'
import { Link } from 'gatsby'
import { jsx, Link as ExternalLink } from 'theme-ui'
import checkIfExternalLink from '../../helpers/checkIfExternalLink'

export default function LinkSelector({ name, link, icon, children, ...rest }) {
  const isExternalLink = checkIfExternalLink(link)

  // sometimes links need to wrap boxs with no text. this handles that.
  const childrenSelector = (
    <>
      {children ? (
        children
      ) : (
        <>
          {icon} {name}
        </>
      )}
    </>
  )

  const internalLink = (
    <Link to={link} {...rest}>
      {childrenSelector}
    </Link>
  )

  const externalLink = (
    <ExternalLink href={link} {...rest}>
      {childrenSelector}
    </ExternalLink>
  )

  return isExternalLink ? externalLink : internalLink
}

LinkSelector.defaultProps = {
  name: '',
  link: '',
  icon: ''
}
