export default {
  loadingApp: true,
  configData: false,
  businessData: false,
  stories: false,
  poweredImages: false,
  // articles:false,
  appState: {
    reservationsPopUp: false,
    onlineOrderWidget: false,
    contactFormPopUp: false
  }
}
