import React from 'react'
import { Link } from 'gatsby'
import { Box, Heading, Image, Text, Link as ExternalLink } from 'theme-ui'
// import Logo from '../../assets/logo.png'
import { footerLinks } from '../navLinks'
import Logo from '../UI/Logo'
import TopFooter from './TopFooter'
import LinkSelector from '../ComponentSelectors/LinkSelector'
import GonationLogoPowered from '../UI/GonationLogoPowered'
import Popup from './Popup'

export default function Footer() {
  const renderFooterLinks = () =>
    footerLinks.map(footerLink => {
      const { name, link, icon } = footerLink
      return (
        <LinkSelector to={link} key={`footerLink-${name}`}>
          {name}
        </LinkSelector>
      )
    })

  return (
    <>
      <TopFooter />
      <Box sx={styles.container}>
        <Logo sx={styles.logo} />
        <Box sx={styles.footerLinks}>{renderFooterLinks()}</Box>
      </Box>

      <Box sx={styles.gonationFooter}>
        <GonationLogoPowered />
      </Box>
      <Box className="popup">
        <Popup />
      </Box>
    </>
  )
}
const styles = {
  // ? === containers ===
  container: {
    padding: '1rem',
    display: 'flex',
    flexDirection: ['column', '', 'row'],
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'black',
    position: 'relative'
  },
  logo: {
    // filter: 'brightness(0)',
    maxHeight: '100px',
    paddingRight: ['', '', '2rem']
  },
  footerLinks: {
    padding: '1rem',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: ['center', '', 'flex-start'],
    alignItems: 'center',
    borderLeft: ['', '', 'solid 1px lightgrey'],
    a: {
      color: 'primary',
      margin: '1rem',
      transition: 'all ease-in-out 0.3s',
      textDecoration: 'none',
      ':hover': {
        textDecoration: 'underline',
        color: 'grey'
      }
    }
  },

  gonationFooter: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#242424',
    width: '100%',
    padding: '1rem 1rem 0rem',
    paddingBottom: '3.5rem',
    position: 'relative'
  }
}
