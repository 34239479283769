import React, { useContext } from 'react'

import { Link } from 'gatsby'
import { Box, Heading, Image, Text, Link as ExternalLink } from 'theme-ui'

import { store } from '../../context/store'
import SocialIcons from '../UI/SocialIcons'
import Address from '../UI/Address'
import Phone from '../UI/Phone'
export default function TopFooter() {
  return (
    <Box sx={styles.container}>
      <Box sx={styles.map}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d752.3287919780643!2d-73.58494327075037!3d41.04023641023261!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c29897b5937bd1%3A0xe6ae8156b1c44f18!2sTony&#39;s%20at%20the%20J%20House!5e0!3m2!1sen!2sus!4v1635529712552!5m2!1sen!2sus"
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </Box>
      <Box sx={styles.content}>
        <Heading sx={styles.heading} as="h3">
          Get In Touch
        </Heading>
        <Box sx={styles.contactDetails}>
          <Phone sx={styles.phone} />
          <Box sx={styles.seperator} />
          <Address sx={styles.address} />
        </Box>
        <SocialIcons />
      </Box>
    </Box>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: ['column', '', 'row'],
    justifyContent: 'center',
    alignItems: 'stretch',
    position: 'relative',
    width: '100%',
    backgroundColor: 'white'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: ['calc(100% - 2rem)', '', 'calc(50% - 2rem)'],
    padding: ['1rem', '', '2rem'],
    backgroundColor: 'primary',
    margin: '1rem'
  },
  heading: {
    color: 'white',
    fontWeight: 'normal',
    textTransform: 'uppercase',
    marginBottom: '1.5rem',
    fontSize: '2.5rem'
  },
  map: {
    width: ['calc(100% - 2rem)', '', 'calc(50% - 2rem)'],
    margin: '1rem',
    iframe: {
      height: '100%',
      width: '100%',
      minHeight: '400px',
      border: 'none'
    }
  },
  contactDetails: {
    display: 'flex',
    width: '100%'
  },
  phone: {
    color: 'white',
    fontSize: '1rem',
    padding: '1rem',
    width: '50%',
    svg: {
      color: 'light'
    },
    transition: 'all ease-in-out 0.6s',
    ':hover': {
      opacity: '0.6'
    }
  },
  seperator: {
    borderRight: 'solid 1px white'
  },
  address: {
    color: 'white',
    padding: '1rem',
    fontSize: '1rem',
    width: '50%',
    svg: {
      color: 'white'
    },
    transition: 'all ease-in-out 0.6s',
    ':hover': {
      opacity: '0.6'
    }
  }
}
