import React, { useContext } from 'react'
import { Box, Text, Heading } from 'theme-ui'
import ReservationsWidget from './ReservationsWidget'
import { store } from '../../context/store'
export default function ReservationsPopUp({ sx, ...rest }) {
  const { globalState, dispatch } = useContext(store)

  const popUpOpenStyles = globalState.appState.reservationsPopUp ? { ...styles.popUpOpen } : {}

  const toggleWidget = () => {
    dispatch({
      type: 'TOGGLE_RESERVATIONS_WIDGET'
    })
  }

  return (
    <>
      <Box {...rest} sx={{ ...styles.label, sx }} onClick={toggleWidget}>
        Reservations
      </Box>
      <Box sx={{ ...styles.popUp, ...popUpOpenStyles }}>
        <ReservationsWidget />
      </Box>
    </>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column'
  },

  label: {
    variant: 'buttons.primary',
    padding: '0.5rem',
    margin: '0rem 0rem 0rem 0.5rem',
    fontSize: '0.75rem',
    cursor: 'pointer',
    borderRadius: ['0px', '', '50px']
    // display: 'flex',
  },
  popUp: {
    position: 'fixed',
    top: '-200px',
    left: '0px',
    transition: 'all ease-in-out 1s',
    opacity: '-10',
    '#ot-reservation-widget': {
      width: ['100%', '100vw', '', '100vw'],
      backgroundColor: '#292f3fbf',
      borderBottom: '1px solid lightgrey'
    },
    '.ot-title': {
      display: 'none'
    },
    '.ot-dtp-picker.wide': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: ['100%', '', '', 'inherit'],
      padding: '0.25rem 1rem'
    },
    '.ot-dtp-picker .ot-dtp-picker-selector': {
      height: '30px'
    },
    '.ot-dtp-picker.wide .ot-dtp-picker-form': {
      height: ['auto', '', '', '33px']
    },
    '.ot-dtp-picker.wide .ot-dtp-picker-form .ot-dtp-picker-selector': {
      fontSize: '0.8rem',
      width: ['100%', '', '', '150px']
    },
    '.ot-dtp-picker .ot-dtp-picker-selector select': {
      fontSize: '0.8rem'
    },
    '.ot-dtp-picker .ot-dtp-picker-selector .ot-dtp-picker-selector-link': {
      padding: '0.4rem 2rem'
    },
    '.ot-dtp-picker .ot-dtp-picker-selector .ot-dtp-picker-selector-link:before': {
      top: 'unset'
    },

    '.ot-dtp-picker.wide .ot-dtp-picker-form .ot-dtp-picker-button': {
      height: '31px',
      padding: '0rem',
      width: ['100%', '', '', '200px']
    }
  },
  popUpOpen: {
    top: '80px',
    opacity: '1'
  }
}
