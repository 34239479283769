import React, { useContext } from 'react'
import { Textarea, Input, Button, Box } from 'theme-ui'
import { store } from '../context/store'

export default function ContactForm(props) {
  const { globalState, dispatch } = useContext(store)
  const { sx, style } = props
  const formName = 'contact-form'

  return (
    <Box
      sx={{ ...styles.container, ...style, ...props.sx }}
      as="form"
      name={formName}
      method="POST"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      <Input type="hidden" name="form-name" value={formName} />
      <Input
        sx={styles.input}
        name="name"
        type="text"
        id="name"
        placeholder="Name"
        className="inputField name"
        required
      />
      <Input sx={styles.input} type="phone" name="phone" id="phone" placeholder="Phone" className="inputField email" />
      <Input
        sx={styles.input}
        type="email"
        name="email"
        id="email"
        placeholder="Email"
        className="inputField email"
        required
      />

      <Textarea
        sx={styles.textArea}
        name="message"
        id="message"
        rows="4"
        placeholder="Message"
        className="textField message"
        required
      />
      <Button className="button submitBtn" type="submit">
        Submit
      </Button>
    </Box>
  )
}

const styles = {
  container: {},
  input: {
    border: 'none',
    borderBottom: 'solid 1px lightgrey',
    margin: '0.5rem 0rem',
    '::placeholder': {
      fontSize: '0.7rem'
    }
  },
  textArea: {
    border: 'none',
    borderBottom: 'solid 1px lightgrey',
    margin: '0.5rem 0rem',
    resize: 'none',
    '::placeholder': {
      fontSize: '0.7rem'
    }
  }
}

// ContactForm.defaultProps = {
//   formName: 'contact-form',
//   sx: {},
// }
