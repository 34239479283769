import React from 'react'
import { Box, Text, Heading, Image } from 'theme-ui'
import Address from '../UI/Address'
import ContactFormPopUp from '../UI/ContactFormPopUp'
import HoursPopUp from '../Hours/HoursPopUp'

export default function BottomNav() {
  return (
    <Box sx={styles.container}>
      {/* <Box sx={styles.logo}>Tony's At The J House</Box> */}
      <Address sx={styles.address} />
      <HoursPopUp style={styles.hours} />
      <ContactFormPopUp />
    </Box>
  )
}

const styles = {
  container: {
    position: 'fixed',
    left: '0',
    bottom: '0',
    right: '0',
    width: '100%',
    // borderTop: '1px solid lightgrey',
    height: '42px',
    padding: ['0.5rem', '', '0.5rem 2rem'],
    backgroundColor: '#0c2240',
    zIndex: '6000',
    display: 'flex',
    color: 'white',
    fontFamily: 'subheading'
  },
  logo: {
    display: ['none', '', 'flex'],
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    maxHeight: '100%',
    img: {
      height: '100%',
      marginRight: '2'
    }
  },
  hours: {
    marginRight: '1rem'
  },

  address: {
    // fontSize: '0.7rem',
    opacity: '0.8',
    // margin: '0rem 1rem 0rem auto',
    padding: '0rem 1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: '1',
    margin: '0rem auto 0rem 0rem'
  }
}
