import React, { useContext } from 'react'
import FacebookLogo from './Icons/Facebook'
import GonationLogo from './Icons/GoNationMan'
import InstagramLogo from './Icons/Instagram'
import LinkedinLogo from './Icons/Linkedin'
import PinterestLogo from './Icons/Pinterest'
import Twitter from './Icons/Twitter'
import YouTube from './Icons/YouTube'
import { store } from '../../context/store'

import { Flex, Link } from 'theme-ui'

export default function SocialIcons() {
  const { globalState, dispatch } = useContext(store)

  const { links, slug } = globalState.businessData

  return (
    <Flex sx={styles.container}>
      {links.facebook ? (
        <Link href={`${links.facebook}`} target="_blank" rel="noreferrer" className="socialLink facebook">
          <FacebookLogo />
        </Link>
      ) : (
        ''
      )}
      {links.instagram ? (
        <Link href={`${links.instagram}`} target="_blank" rel="noreferrer" className="socialLink instagram">
          <InstagramLogo />
        </Link>
      ) : (
        ''
      )}
      {links.linkedin ? (
        <Link href={`${links.linkedin}`} target="_blank" rel="noreferrer" className="socialLink linkedin">
          <LinkedinLogo />
        </Link>
      ) : (
        ''
      )}
      {links.youtube ? (
        <Link href={`${links.youtube}`} target="_blank" rel="noreferrer" className="socialLink youtube">
          <YouTube />
        </Link>
      ) : (
        ''
      )}
      {links.twitter ? (
        <Link href={`${links.twitter}`} target="_blank" rel="noreferrer" className="socialLink twitter">
          <Twitter />
        </Link>
      ) : (
        ''
      )}
      {links.pinterest ? (
        <Link href={`${links.pinterest}`} target="_blank" rel="noreferrer" className="socialLink pinterest">
          <PinterestLogo />
        </Link>
      ) : (
        ''
      )}

      {slug ? (
        <Link
          href={`https://www.gonation.com/place/${slug}/pulse`}
          target="_blank"
          rel="noreferrer"
          className="socialLink gonation"
        >
          <GonationLogo />
        </Link>
      ) : (
        ''
      )}
    </Flex>
  )
}

const styles = {
  container: {
    margin: '1rem 0rem',
    svg: {
      margin: '0.5rem',
      width: '40px',
      height: '40px',
      transition: 'all ease-in-out 0.6s',
      border: 'solid 1px',
      borderRadius: '100px',
      padding: '0.5rem',
      path: {
        transition: 'all ease-in-out 0.6s',
        fill: 'light'
      },
      ':hover': {
        backgroundColor: 'white',
        path: {
          fill: 'primary'
        }
      }
    }
  }
}
