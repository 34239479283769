import React, { useContext } from 'react'
import { Box, Text, Heading } from 'theme-ui'
import ContactForm from '../ContactForm'
import { FaPhoneAlt } from 'react-icons/fa'
import { AiOutlineClose } from 'react-icons/ai'
import Phone from '../UI/Phone'
import Address from '../UI/Address'
import { store } from '../../context/store'

export default function ContactFormPopUp() {
  const { globalState, dispatch } = useContext(store)

  const toggleWidget = () => {
    dispatch({
      type: 'TOGGLE_CONTACT_FORM_POPUP'
    })
  }

  return (
    <>
      <Text sx={styles.label} onClick={toggleWidget}>
        <FaPhoneAlt /> How Can We Help You?
      </Text>
      <Box
        sx={{
          ...styles.popUpContainer,
          right: `${globalState.appState.contactFormPopUp ? '0.5rem' : '-100vw'}`
        }}
      >
        <AiOutlineClose sx={styles.closeBtn} onClick={toggleWidget} />
        <Heading as="h4" sx={styles.title}>
          Get In Touch
        </Heading>
        <Phone sx={styles.phone} />
        <Address sx={styles.address} />
        <ContactForm formName={'contact-form-pop-up'} />
      </Box>
    </>
  )
}

const styles = {
  container: {},
  label: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '0.7rem',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: 'inherit',
    letterSpacing: '1px',
    cursor: 'pointer',
    transition: 'all ease-in-out 0.5s',
    svg: {
      fontSize: '1rem',
      marginRight: 2,
      color: 'primary'
    },
    ':hover': {
      textDecoration: 'underline'
    }
  },

  popUpContainer: {
    position: 'fixed',
    right: '-100vw',
    bottom: ['50px'],
    transition: 'all ease-in-out 1s',
    backgroundColor: '#f9f9f9',
    padding: ['2rem', '', '3rem 3rem'],
    border: 'solid lightgrey 1px',
    boxShadow: '0 0 3px 0 rgb(0 0 0 / 30%)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    // maxWidth:['calc(100% - 2rem)'],
    // width:'100%',
    // maxWidth:'300px',
    maxHeight: '90vh'
  },

  title: {
    borderBottom: 'solid 1px lightgrey',
    color: 'grey',
    textTransform: 'uppercase',
    marginBottom: '1.5rem',
    fontSize: '1.5rem'
  },
  phone: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'grey',
    textAlign: 'center',
    marginBottom: '1rem',
    fontSize: '0.8rem',
    svg: {
      width: '16px',
      height: '16px',
      color: 'primary',
      marginRight: '2'
    }
  },
  address: {
    color: 'grey',
    marginBottom: ['0.5rem', '', '1rem'],
    svg: {
      width: '16px',
      height: '16px',
      color: 'primary',
      marginRight: '2'
    }
  },

  closeBtn: {
    position: 'absolute',
    right: '0.5rem',
    top: '0.5rem',
    width: '30px',
    opacity: '0.8',
    height: '30px',
    color: 'white',
    padding: '0.25rem',
    backgroundColor: 'primary'
  },

  contactForm: {
    h4: {
      marginBottom: '1rem'
    },
    input: {
      margin: '0rem 0rem 0.5rem'
    },
    textarea: {
      margin: '0rem 0rem 0.5rem'
    }
  }
}
