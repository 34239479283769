import React, { useEffect } from 'react'
import { Box } from 'theme-ui'

export default function ScriptWidget({ ...rest }) {
  const scriptSrcMobile =
    '//www.opentable.com/widget/reservation/loader?rid=88156&type=standard&theme=wide&iframe=false&lang=en-US&newtab=false&ot_source=Restaurant%20website'
  // const scriptSrcDesktop =
  //   '//www.opentable.com/widget/reservation/loader?rid=47125&type=wide&theme=standard&iframe=true&lang=en-US&newtab=false&ot_source=Restaurant%20website'

  useEffect(() => {
    const scriptMobile = document.createElement('script')
    scriptMobile.type = 'text/javascript'
    scriptMobile.src = scriptSrcMobile
    scriptMobile.async = true
    document.getElementById('scriptWidgetMobile').appendChild(scriptMobile)

    // const scriptDesktop = document.createElement('script')
    // scriptDesktop.type = 'text/javascript'
    // scriptDesktop.src = scriptSrcDesktop
    // scriptDesktop.async = true
    // document.getElementById('scriptWidgetDesktop').appendChild(scriptDesktop)
    return () => {}
  }, [])

  return (
    <>
      <Box sx={styles.container} id={'scriptWidgetMobile'}></Box>
      {/* <Box sx={styles.container} id={'scriptWidgetDesktop'}></Box> */}
    </>
  )
}
const styles = {
  container: {
    // padding: '10rem 1rem',
  }
}
