import ls from 'local-storage'
import React, { useState } from 'react'
import { MdClose } from 'react-icons/md'
import { useLocation } from '@reach/router'
import { Box, Heading } from 'theme-ui'
import Slider from 'react-slick'
import cloudinaryIdToURL from '../../helpers/cloudinaryIdToURL'
import useStories from '../../hooks/useStories'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import LinkSelector from '../ComponentSelectors/LinkSelector'
import { nanoid } from 'nanoid'

const NextArrow = ({ currentSlide, slideCount, ...props }) => <FontAwesomeIcon {...props} icon={faChevronRight} />

const PrevArrow = ({ currentSlide, slideCount, ...props }) => <FontAwesomeIcon {...props} icon={faChevronLeft} />

const defaultSettings = {
  infinite: true,
  arrows: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  dots: true,
  autoplay: true,
  speed: 1500,
  autoplaySpeed: 5000
}

// ? =================
// ? === Functions ===
// ? =================

const dateInPast = function (firstDate, today) {
  if (firstDate.setHours(0, 0, 0, 0) <= today.setHours(0, 0, 0, 0)) {
    return true
  }
  return false
}

// ? =========================
// ? === Default Component ===
// ? =========================

function Popup() {
  const { stories, getStoriesByTag } = useStories()

  const location = useLocation()

  const isHomepage = location.pathname === `/`
  const locationPath = location.pathname.split('/').filter(el => el)
  const locationLastSlug = locationPath.pop()
  const homepagePopKey = 'popup'
  const currentPopupKey = `popup-${locationLastSlug ? `${locationLastSlug}` : ''}`
  const currentPagePopUpKey = isHomepage ? homepagePopKey : currentPopupKey

  if (stories) {
    const story = getStoriesByTag(currentPagePopUpKey)?.[0]
    const storyExpiryDate = story?.tags?.find(tag => tag.includes('expires-'))?.replace('expires-', '')
    const storyRecur = story?.tags?.find(tag => tag.includes('recur-'))?.replace('recur-', '') ?? undefined
    if (story) {
      return (
        <PopupStory {...story} expires={storyExpiryDate ? new Date(storyExpiryDate) : undefined} recur={storyRecur} />
      )
    } else return null
  } else {
    return null
  }
}

export default Popup

// ? ===========================
// ? === Secondary Component ===
// ? ===========================

function PopupStory({
  title,
  subtitle,
  body,
  media,
  ctaLinks,
  expires = new Date('31 July 2050'),
  recur = 3,
  ...rest
}) {
  const [showPopup, setShowPopup] = useState(true)
  console.log('popup')
  // this is an automatic remover for a pop up.
  const today = new Date()
  const popupRemovalDate = expires
  const dateIsPast = dateInPast(popupRemovalDate, today)

  if (dateIsPast) return null

  const closePopup = () => {
    var numberDaysToExpire = recur
    // create new current date
    var expireDate = new Date()
    // add number of days too current date and set expire date to then
    expireDate.setDate(expireDate.getDate() + numberDaysToExpire)
    const dateString = JSON.stringify({
      expireDate
    })
    localStorage.setItem('popUpExpires', dateString)
    setShowPopup(false)
  }

  const hasSeenPopup = Boolean(ls.get('popUpExpires'))
  // if there is an object there check if the expiry date is less than now. if so toggle pop up
  const isExpiredPopup =
    hasSeenPopup && new Date(JSON.parse(localStorage.getItem('popUpExpires')).expireDate).getTime() < Date.now()
  if (!showPopup || (hasSeenPopup && !isExpiredPopup)) {
    return ''
  } else {
    console.log(media)
    const images = media?.map(image => image && cloudinaryIdToURL(image?.cloudinaryId))

    return (
      <Box sx={boxContainer}>
        <Box sx={background} onClick={closePopup} />
        <Box onClick={closePopup} sx={closeBox}>
          <MdClose size={'24px'} color="white" />
        </Box>
        <Box sx={innerBox}>
          <Box sx={content}>
            <Box sx={sliderStyles}>
              <Slider {...{ ...defaultSettings, autoplaySpeed: 3000 }}>
                {images?.map((image, index) => (
                  <img key={image + index} src={image} />
                ))}
              </Slider>
            </Box>

            <Heading as="h2" className="title">
              {title}
            </Heading>
            <Heading as="h4" className="subtitle">
              {subtitle}
            </Heading>
            <Box dangerouslySetInnerHTML={{ __html: body }} className="text" />
          </Box>

          <Box sx={buttonsContainer}>
            {console.log(ctaLinks)}
            {Object.values(ctaLinks).map(link => {
              console.log(link)
              return link ? (
                <LinkSelector key={nanoid()} name={link.title} link={link.url} icon={undefined} sx={buttonStyles} />
              ) : (
                ''
              )
            })}
          </Box>
        </Box>
      </Box>
    )
  }
}

// ? ==============
// ? === Styles ===
// ? ==============

const boxContainer = {
  width: '100%',
  height: '100vh',
  left: '0rem',
  top: '0rem',
  position: 'fixed',
  zIndex: 5000,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}

const buttonStyles = {
  variant: 'buttons.primary'
}

const background = {
  backgroundColor: 'rgba(0,0,0,.6)',
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%,-50%)'
}

const innerBox = {
  backgroundColor: '#0c2240',
  borderRadius: '8px',
  maxWidth: 700,
  zIndex: 9999,
  position: 'relative',
  border: '2px solid',
  borderColor: 'secondary',
  padding: '2rem',
  pb: '8rem',
  width: '95%',
  maxHeight: '90vh',
  color: 'white',
  overflowY: 'scroll'
}

const content = {
  maxHeight: 'calc(90vh - 150px)',
  overflowY: 'scroll',
  '::-webkit-scrollbar': {
    display: 'none'
  },
  '-ms-overflow-style': 'none' /* IE and Edge */,
  scrollbarWidth: 'none' /* Firefox */,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  '.title': {
    variant: 'text.title',
    padding: '0rem',
    mb: '1rem',
    textAlign: 'center'
  },
  '.subtitle': {
    variant: 'text.subtitle',
    padding: '0rem',
    mb: '1rem',
    textAlign: 'center'
  },
  '.text': {
    variant: 'text.text',
    my: '2rem',
    color: 'white',
    textAlign: 'center',
    '*': {
      color: 'inherit',
      margin: ' 0rem'
    }
  }
}

const sliderStyles = {
  mb: '2rem',
  height: '400px',
  width: '100%',
  '.slick-slider': {
    maxHeight: '50vh',
    width: '100%',
    height: 'inherit',
    '.slick-list, .slick-track': {
      height: 'inherit'
    },

    '.slick-slide': {
      height: 'inherit',
      '> div': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'inherit',
        img: {
          height: 'inherit'
        }
      }
    }
  },
  img: {
    objectFit: 'contain',
    objectPosition: 'center',
    maxHeight: '50vh',
    height: 'inherit'
  }
}

const buttonsContainer = {
  position: 'absolute',
  backgroundColor: 'rgba(20,20,20,0.9)',
  bottom: '0%',
  left: '0%',
  width: '100%',
  p: '1rem 1rem',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center'
}

const closeBox = {
  position: 'absolute',
  top: 0,
  right: 0,
  zIndex: 99,
  svg: {
    height: '40px',
    width: '40px',
    border: 'solid 1px white',
    borderRadius: '60%',
    fill: 'white!important',
    transition: 'all .3s ease-in-out',
    ':hover': {
      backgroundColor: 'primary',
      color: 'white'
    }
  }
}
